import axios from "axios"

// https://api.apartments.jarvis.oohdesk.ru/apartment/list?month_fee=30000&page=1
// https://api.apartments.jarvis.oohdesk.ru/apartment/price-limits
export class ApiService {
  /**
   * 
   * @param {*} param0 
   */
  constructor({ baseURL, user }) {
    this.client = axios.create({ baseURL })
    this.user = user
  }

  /**
   * updateFavorite
   * @param {*} id 
   */
  async updateFavorite(id) {
    try {
      const { data } = await this.client.post("favorite/update", {
        user_id: this.user.id,
        entity_id: id,
      })

      return data
    } catch {
      throw "Не удалось обновить избранное"
    }
  }

  /**
   * importFavorite
   * @param {*} ids
   */
   async importFavorite(ids) {
    try {
      const { data } = await this.client.post("favorite/import", {
        user_id: this.user.id,
        entity_ids: ids,
      })

      return data
    } catch {
      throw "Не удалось обновить избранное"
    }
  }

  /**
   * fetchFavorites
   */
  async fetchFavorites() {
    try {
      const params = {
        user_id: this.user.id,
        page: 1
      }
      const { data } = await this.client.get("favorite/list", { params })

      return data.items || []
    } catch {
      throw "Не удалось получить избранное"
    }
  }

  /**
   * fetchApartments
   * @param {*} param0 
   * @returns 
   */
  async fetchApartments({ cost, page }) {
    try {
      const params = {
        tg_user_id: this.user.id,
        month_fee: cost,
        page,
      }
      const { data } = await this.client.get("apartment/list", { params })

      return data
    } catch {
      throw "Не удалось собрать каталог"
    }
  }

  /**
   * fetchCostLimit
   */
  async fetchCostRange() {
    try {
      const { data } = await this.client.get("apartment/price-limits")

      return {
        min: parseInt(data.min),
        max: parseInt(data.max),
      }
    } catch {
      throw "Не удалось запросить диапазон цен"
    }
  }
}
