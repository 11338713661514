import axios from "axios"

// https://api.apartments.jarvis.oohdesk.ru/tg/user/init
export class UserService {
  /**
   * 
   * @param {*} param0 
   */
  constructor({ baseURL }) {
    this.client = axios.create({ baseURL })
    this.user = null
  }

  get favorites() {
    return this.get("favorites", [])
  }

  setFavorites(favorites) {
    this.set("favorites", favorites)
  }

  unsetFavorites() {
    this.set("favorites.backup", this.favorites)
    this.set("favorites", [])
  }

  getUser() {
    return this.user || { id: 0 }
  }

  /**
   * init
   */
  async init(tgUser) {
    try {
      const { data } = await this.client.post("tg/user/init", {
        id: tgUser.id,
        first_name: tgUser.first_name,
        last_name: tgUser.last_name,
        username: tgUser.username,
        language_code: tgUser.language_code,
        is_bot: tgUser.is_bot,
        is_premium: tgUser.is_premium
      })

      this.user = data

      return this.user
    } catch {
      throw "Не удалось инициализировать пользователя"
    }
  }

  /**
	 * Получить данные из хранилища
	 * @param {String} key
	 * @param {*} defaultValue
	 */
	get(key, defaultValue = null) {
		const value = JSON.parse(localStorage.getItem(key))

		return value !== null ? value : defaultValue
	}

	/**
	 * Установить данные в хранилище
	 * @param {String} key
	 * @param {Object|String|Number} value
	 */
	set(key, value) {
		localStorage.setItem(key, JSON.stringify(value))
	}
}
