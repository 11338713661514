<template>
	<div ref="app" class="app" :class="appClass">
		<!-- <Console v-if="isDebug" /> -->
		<!-- <Log :data="debugData" /> -->
	
		<ExternaView v-if="externalUrl" :url="externalUrl" />
		<template v-else>
			<Header v-if="hasHeader" class="app__header" />
			<Content />
			<Footer v-if="hasFooter" class="app__footer" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: "App",

	components: {
		// Console: () => import("@/components/Console"),
		Log: () => import("@/components/Log"),
		Header: () => import("@/components/Header"),
		Content: () => import("@/components/Content"),
		ExternaView: () => import("@/components/ExternaView"),
		Footer: () => import("@/components/Footer"),
		Spinner: () => import("@/components/ui/Spinner")
	},

	computed: {
		...mapGetters([
			"isDebug",
			"isLightTheme", 
			"isDarkTheme", 
			"hasHeader", 
			"hasFooter", 
			"externalUrl",
			"debugData",
			"user"
		]),

		appClass() {
			return {
				"theme-light": this.isLightTheme,
				"theme-dark": this.isDarkTheme
			}
		},
	},

	methods: {
		...mapActions(["init", "toggleTheme", "log"]),
	},

	mounted() {
		this.init()

		if (this.isDebug) {
			window.toggleTheme = () => this.toggleTheme()
		}

		this.log({
			appUrl: location.href,
			params: Object.fromEntries(new URL(location.href).searchParams.entries()),
			user: this.user
		})
	}
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
@import "@/assets/scss/app.scss";

:root {
	--viewport-width: 100%;
	--viewport-height: 100%;
}

.app {
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	font-family: "SF Pro Display", Helvetica, Arial, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	background: var(--bg-color);
	color: var(--text-color);

	&__header {
		max-height: $headerHeight;
	}
	&__footer {
		max-height: $footerHeight;
	}
}
</style>
