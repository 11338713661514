import Vue from "vue"
import App from "./App"
import initService from "./service"
import initStore from "./store"
import config from "./config/main.local"

/**
 * Init App
 */
const initApp = async ({ config }) => {
  const service = await initService({ config })
  const store = initStore({ config, service })
  const mount = () => new Vue({ store, render: h => h(App) }).$mount("#app")

  Vue.config.productionTip = !config.debug

  setTimeout(mount, 100)
}

initApp({ config })
